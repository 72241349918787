<template>
  <a
    :href
    data-component-name="Buttons.Anchor"
  >
    <Base :size :fill :accent :disabled>
      <slot />
    </Base>
  </a>
</template>

<script setup lang="ts">
// components
import Base from './Base.vue';

// types
import type { BaseButtonProps } from './Base.types';

type ButtonsAnchorProps =
  Partial<BaseButtonProps> &
  {
    anchor: string
  }

const PREFIX = '#';

const props = withDefaults(
  defineProps<ButtonsAnchorProps>(),
  {
    anchor: PREFIX,
  },
);

const href = computed(() => {
  const { anchor } = props;

  if (!anchor.startsWith(PREFIX)) {
    return PREFIX + anchor;
  }

  return anchor;
});
</script>
