import Cookie from '~/services/Cookie';

import { REGEX } from '~/constants';
import { isValidURL } from '~/utils';

const ENTRY_PAGE_KEY = 'EntryPage';
const REFERER_PAGE_KEY = 'RefererPage';

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) return;

  void callOnce(() => {
    const savedRefererPage = Cookie.get(REFERER_PAGE_KEY) || '';
    const {
      referrer: newRefererPage,
      location: {
        href: newEntryPage,
      },
    } = document;

    if (isValidURL(newRefererPage)) {
      if (!REGEX.O1_DOMAIN.test(newRefererPage)) {
        if (newRefererPage !== savedRefererPage) {
          Cookie.set(REFERER_PAGE_KEY, newRefererPage);
          Cookie.set(ENTRY_PAGE_KEY, newEntryPage);
        }
      }
    }
  });
});
